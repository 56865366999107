import React from 'react';

import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';

import { DynamicImage, Text } from 'components';
import ContinueButton from '../QuizContinueButton';
import { Color } from 'utils/theme';

import Star from 'assets/icons/star.svg';
import IsVerified from 'assets/icons/isverified.svg';
import ReviewsStarsRow from 'components/reviews/ReviewsStarsRow';
import { Textsms } from '@material-ui/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem 1.5rem 1.5rem;
  max-width: 500px;
  margin: auto;
  background: #fff;
  @media ${tablet} {
    padding: 1rem;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  max-width: 1140px;
  @media ${tablet} {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  @media ${tablet} {
    width: 100%;
  }
`;
const StyledDynamicImage = styled(DynamicImage)`
  border-radius: 1rem;
  @media ${tablet} {
    max-height: 250px;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const BottomContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;
`;

const IsVerifiedIcon = styled(IsVerified)`
  height: 22px;
  margin-left: auto;
`;
const TextNoWrap = styled(Text)`
  white-space: nowrap;
`;
const StyledText = styled(Text)`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
`;

const Description = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  span {
    font-style: normal;
    font-weight: 700;
  }
`;

type TextAndImgColumnProps = {
  onContinue: () => void;
  bottomText?: string;
  image?: string;
  image_mob?: string;
  alt?: string;
  title?: string;
  description?: string;
  nextBtnTitle?: string;
  btnColor?: Color;
  bottomReviews?: boolean;
  bottomVerifiedIcon?: boolean;
  cornerVariant?: 'default' | 'round';
};

const TextAndImgColumn = ({
  image,
  image_mob,
  bottomText,
  bottomReviews,
  bottomVerifiedIcon,
  alt,
  title,
  description,
  nextBtnTitle,
  btnColor = 'orange',
  cornerVariant = 'round',
  onContinue,
}: TextAndImgColumnProps) => {
  const { isTablet } = useQuery();
  const reviewsArray = [0, 1, 2, 3, 4, 5];
  return (
    <Container>
      <Content>
        {(image || image_mob) && (
          <ImageContainer>
            <StyledDynamicImage
              src={isTablet ? image_mob || image : image}
              alt={alt || 'info'}
            />
          </ImageContainer>
        )}

        <TextContainer>
          {title && (
            <StyledText dangerouslySetInnerHTML={{ __html: title || '' }} />
          )}

          {description && (
            <Description
              dangerouslySetInnerHTML={{ __html: description || '' }}
            />
          )}
          {bottomText && (
            <BottomContentWrapper>
              <TextNoWrap type={isTablet ? 'body' : 'body'} color="dark90">
                {bottomText}
              </TextNoWrap>
              {bottomReviews && <ReviewsStarsRow />}
              {bottomVerifiedIcon && <IsVerifiedIcon />}
            </BottomContentWrapper>
          )}
        </TextContainer>
      </Content>
    </Container>
  );
};

export default TextAndImgColumn;
